/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "caption_colors_primary": "text-gray-900 group-data-[status=error]:text-error-600",
  "caption_size_default": "text-body-xs",
  "caption_size_alternative": "text-body-xs",
  "caption_variant_default": "transition-colors",
  "caption_variant_alternative": "transition-colors",
  "group_colors_primary": "",
  "group_size_default": "w-full",
  "group_variant_default": "relative flex flex-col items-start gap-y-1",
  "icon_colors_primary": "text-black group-data-[status=error]:text-error-600",
  "icon_size_default": "w-5 h-5",
  "icon_size_alternative": "w-5 h-5 group-focus-within:w-3.5 group-focus-within:h-3.5 group-data-[filled]:w-3.5 group-data-[filled]:h-3.5",
  "icon_variant_default": "transition-all absolute right-2 top-1/2 -translate-y-1/2 peer/icon",
  "icon_variant_alternative": "transition-all absolute right-2 top-1/2 -translate-y-1/2 peer/icon group-focus-within:top-2 group-focus-within:translate-y-0 group-data-[filled]:top-2 group-data-[filled]:translate-y-0",
  "input_colors_primary": "bg-white outline outline-0 outline-offset-1 outline-primary-200 ring-1 ring-gray-700 ring-inset hover:not-focus:bg-white hover:not-focus:outline-0 hover:not-focus:ring-1 hover:not-focus:ring-black focus:bg-primary-50 focus:outline-4 focus:ring-2 focus:ring-primary-700 placeholder:text-gray-700 group-data-[status=error]:bg-error-50 group-data-[status=error]:outline group-data-[status=error]:outline-4 group-data-[status=error]:outline-error-200 group-data-[status=error]:ring-2 group-data-[status=error]:ring-error-600 group-data-[status=error]:ring-inset group-data-[status=error]:placeholder:text-gray-700",
  "input_size_default": "p-2 w-full h-10 peer-[.absolute]/icon:pr-7",
  "input_size_alternative": "p-2 pt-6 w-full h-14",
  "input_variant_default": "transition-all rounded",
  "input_variant_alternative": "transition-all rounded placeholder:opacity-0",
  "label_colors_primary": "text-gray-700 group-hover:text-black group-focus-within:text-primary-700 group-data-[status=error]:!text-error-600",
  "label_size_default": "text-body-xs",
  "label_size_alternative": "text-body-sm group-focus-within:text-body-2xs group-data-[filled]:text-body-2xs",
  "label_variant_default": "transition-color relative",
  "label_variant_alternative": "transition-all absolute z-1 left-2 top-1/2 -translate-y-1/2 group-focus-within:top-1 group-focus-within:translate-y-1.5 group-data-[filled]:top-1 group-data-[filled]:translate-y-1.5",
  "size_default": "w-full",
  "size_alternative": "w-full",
  "colors_primary": "",
  "status_error": "",
  "variant_default": "flex flex-col items-start gap-y-1 group",
  "variant_alternative": "flex flex-col items-start gap-y-1 group"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "TextField");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;