/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_primary": "bg-primary-700 hover:bg-primary-600 active:bg-primary-800 focus:ring-primary-200 border-primary-700 text-white",
  "colors_secondary": "bg-white hover:bg-primary-100 focus:ring-primary-200 active:bg-primary-200 border-primary-700 text-primary-700",
  "size_small": "px-5 py-2 text-headline-xs gap-1.5",
  "size_medium": "px-6 py-2.5 text-headline-sm gap-2.5",
  "size_large": "px-6 py-3 text-headline-sm gap-2.5",
  "variant_default": "inline-flex items-center justify-center rounded-full ring-0 active:ring-0 hover:ring-0 focus:ring-4 outline-none focus:outline-none transition-all duration-200 ease-in-out border"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Button");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;